import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import HappyTailCollection from '../../../../../../../../core/scripts/models/HappyTailCollection';
import HappyTailsContentCardCollection from '../../../../../specifics/HappyTailsContentCardCollection';
import HappyTailsSubmissionCard from '../../../../../specifics/HappyTailsSubmissionCard';
import happyTailAPIService from '../../../../../../../../core/scripts/services/happyTailAPIService';
import {
    analyticsHappyTails442,
    analyticsHappyTails443,
} from '../../../../../../analytics/happy-tails';

import withLazyLoad from '../../../../../hoc/withLazyLoad';

class HappyTails extends Component {
    state = {
        happyTailCollection: new HappyTailCollection(),
        isRandomBreed: false,
    };

    static propTypes = {
        breed: PropTypes.string,
        observed: PropTypes.bool, // value come from lazy load HOC
    };

    static defaultProps = {
        observed: true,
    };

    componentDidMount() {
        this._getHappyTailCollection();
    }

    async _getHappyTailCollection() {
        const searchParams = {
            status: 'published',
            limit: '3',
            page: '1',
            breed: this.props.breed,
            include: ['author', 'photo'],
        };
        try {
            const list = await happyTailAPIService.getList(searchParams);
            this.setState({
                happyTailCollection: list.happyTails,
                isRandomBreed: list.isRandomBreed,
            });
        } catch (error) {}
    }

    get submission() {
        return {
            buttonPrimary: 'Tell Us Your Story',
            headline: 'Submit Your Happy Tail',
            linkPrimary: '/happy-tails/your-pet-adoption-story/submission-form/',
            text:
                'Tell us the story of how you met your furry best friend and help other pet lovers discover the joys of pet adoption!',
        };
    }

    /**
     * _onContentCardClick
     * @param {string} title
     * @param {string} cta
     * @param {string} link
     * @fires PetfinderConsumer443
     */
    _onContentCardClick = (title, cta, link) => {
        analyticsHappyTails443(link);
    };

    /**
     * _onSubmissionPrimaryClick
     * @param {string} label
     * @fires PetfinderConsumer442
     */
    _onSubmissionPrimaryClick = label => {
        analyticsHappyTails442();
    };

    render() {
        const showCollection = this.state.happyTailCollection.size > 0;
        const showSubmissionCard = this.state.happyTailCollection.size < 2;
        return (
            <Fragment>
                {showCollection && (
                    <div className="tier tier_full m-tier_bgPrimaryS1 m-tier_coverFixedBottom u-vr8x">
                        <div className="tier-inner">
                            <h2 className="txt txt_h1 m-txt_tight m-txt_colorWhite m-txt_alignCenter u-vr2x u-vrTop6x">
                                {!this.state.isRandomBreed && `${this.props.breed} `}
                                Happy Tails
                            </h2>
                            <a
                                href="/happy-tails/pet-adoption-stories/"
                                className="txt txt_linkWhite txt_linkHighlight m-txt_underline m-txt_lg m-txt_block m-txt_tight m-txt_alignCenter u-vr8x"
                            >
                                View Pet Adoption Stories
                            </a>
                            <div className="grid grid_gutterLg m-grid_stretch m-grid_centerJustify u-vr8x">
                                <HappyTailsContentCardCollection
                                    collection={this.state.happyTailCollection}
                                    onCardClick={this._onContentCardClick}
                                    observed={this.props.observed}
                                >
                                    {showSubmissionCard && (
                                        <div className="grid-col grid-col_2/3@minLg">
                                            <HappyTailsSubmissionCard
                                                submission={this.submission}
                                                onPrimaryButtonClick={this._onSubmissionPrimaryClick}
                                                observed={this.props.observed}
                                            />
                                        </div>
                                    )}
                                </HappyTailsContentCardCollection>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withLazyLoad(HappyTails);
