import { PFElement } from '../../../../core/scripts/elements/pf-element/element';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

// Interface - Export shared symbols if necessary.
// Private Properties
const iframe = Symbol('iframe');
// Private Methods
export const play = Symbol('play');
export const stop = Symbol('stop');

/**
 * Video methods to control iframes for different video services.
 */
const videoMethods = {
    youtube: {
        play: iframe => {
            const cmd = JSON.stringify({
                event: 'command',
                func: 'playVideo',
                args: [],
            });
            iframe.contentWindow.postMessage(cmd, '*');
        },
        stop: iframe => {
            const cmd = JSON.stringify({
                event: 'command',
                func: 'pauseVideo',
                args: [],
            });
            iframe.contentWindow.postMessage(cmd, '*');
        },
    },
    vimeo: {
        play: iframe => {
            const cmd = JSON.stringify({
                method: 'play',
            });
            iframe.contentWindow.postMessage(cmd, '*');
        },
        stop: iframe => {
            const cmd = JSON.stringify({
                method: 'pause',
            });
            iframe.contentWindow.postMessage(cmd, '*');
        },
    },
    facebook: {
        play: () => {},
        stop: () => {},
    },
    vidly: {
        play: iframe => {
            const src = iframe.getAttribute('src');
            const newSrc = src.replace('&autoplay=false', '&autoplay=true');
            iframe.setAttribute('src', newSrc);
        },
        stop: iframe => {
            const src = iframe.getAttribute('src');
            const newSrc = src.replace('&autoplay=true', '&autoplay=false');
            iframe.setAttribute('src', newSrc);
        },
    },
};

/**
 * This component contains an embedded video from Youtube, Vimeo, Facebook, or Vidly.
 * It provides an api for listening for an event to play or stop the video.  Each
 * service has a slightly different method of starting and stopping.  In some cases
 * this is done via postmessaging to an iframe to an api supported by the video service.
 * In others, query parameters are toggled, or the src is manipulated.  Facebook
 * does not support postmessaging or query params in embedded iframes,
 * and cannot be played or stopped programatically.
 *
 * @class PFDCEmbeddedVideo
 * @extends PFElement
 *
 */
export class PFDCEmbeddedVideoElement extends PFElement {
    /**
     * Video service configuration
     * @return {String}
     */
    get service() {
        return this.getAttribute('service');
    }
    /**
     * Update to play the video on
     * @return {String}
     */
    get playOn() {
        return this.getAttribute('play-on');
    }
    /**
     * Update to stop the video on
     * @return {String}
     */
    get stopOn() {
        return this.getAttribute('stop-on');
    }
    /**
     * Run after element is inserted in DOM and internals have been fully parsed.
     * @method onInit
     */
    onInit() {
        /**
         * @property [iframe] to video url
         * @type {HTMLElement}
         */
        this[iframe] = this.querySelector('iframe');
    }
    /**
     * Finds the play methods and runs them on a specified update.
     * @method play
     * @private
     */
    [play]() {
        this.playTimeoutId = setTimeout(() => {
            videoMethods[this.service].play(this[iframe]);
        }, 1000);
    }
    /**
     * Finds the play methods and runs them on a specified update.
     * @method stop
     * @private
     */
    [stop]() {
        if (this.playTimeoutId) {
            clearTimeout(this.playTimeoutId);
        }
        videoMethods[this.service].stop(this[iframe]);
    }

    /**
     * On updates from the event bus, react to relevant events.
     * @method onUpdated
     * @param {Object} event
     * @public
     */
    onUpdated(event) {
        if (event.detail.type === this.playOn) {
            scheduleMicrotask(() => {
                this[play]();
            });
        }
        if (event.detail.type === this.stopOn) {
            scheduleMicrotask(() => {
                this[stop]();
            });
        }
    }
}
