import PFAccordion from '../../../../core/scripts/elements/pf-accordion/element';
import PFAd from '../../../../core/scripts/elements/pf-ad/element';
import PFApp from '../../../../core/scripts/elements/pf-app/element';
import PFDynamic from '../../../../core/scripts/elements/pf-dynamic/element';
import PFElement from '../../../../core/scripts/elements/pf-element/element';
import PFEnsighten from '../../../../core/scripts/elements/pf-ensighten/element';
import PFRecaptcha from '../../../../core/scripts/elements/pf-recaptcha/element';
import PFTruncate from '../../../../core/scripts/elements/pf-truncate/element';

import PFDCTrackingBridge from '../../elements/pfdc-tracking-bridge/element';
import PFDCAdoptionInquiryForm from '../../elements/pfdc-adoption-inquiry-form/element';
import PFDCClipboard from '../../elements/pfdc-clipboard/element';
import PFDCContentTouts from '../../elements/pfdc-content-touts/element';
import PFDCCountrySelect from '../../elements/pfdc-country-select/element';
import { PFDCDispatcherSelectElement } from '../../elements/pfdc-dispatcher/element';
import PFDCFavoriteBtnElement from '../../elements/pfdc-favorite-btn/element';
import PFDCFooter from '../../elements/pfdc-footer/element';
import PFDCHeader from '../../elements/pfdc-header/element';
import PFDCHeaderDropdown from '../../elements/pfdc-header-dropdown/element';
import PFDCHeaderDropdownResources from '../../elements/pfdc-header-dropdown-resources/element';
import PFDCHelloBar from '../../elements/pfdc-hello-bar/element';
import PFDCInput from '../../elements/pfdc-input/element';
import PFDCLazyLoad from '../../elements/pfdc-lazy-load/element';
import PFDCLoginForm from '../../elements/pfdc-login-form/element';
import PFDCMobileMenu from '../../elements/pfdc-mobile-menu/element';
import PFDCModal from '../../elements/pfdc-modal/element';
import PFDCOptins from '../../elements/pfdc-optins/element';
import PFDCPasswordValidator from '../../elements/pfdc-password-validator/element';
import PFDCPositionTracker from '../../elements/pfdc-position-tracker/element';
import PFDCPostalCodeInput from '../../elements/pfdc-postal-code-input/element';
import PFDCRecommendations from '../../elements/pfdc-recommendations/element';
import PFDCRegistrationForm from '../../elements/pfdc-registration-form/element';
import PFDCSavedSearchList from '../../elements/pfdc-saved-search-list/element';
import PFDCSticky from '../../elements/pfdc-sticky/element';
import PFDCTooltipController from '../../elements/pfdc-tooltip-controller/element';
import PFDCPetCard from '../../elements/pfdc-pet-card/element';

import { PFDCEmbeddedCarouselVideoElement } from '../../elements/pfdc-embedded-carousel-video/element';
import { PFDCPetCarouselElement } from '../../../../dotcom/scripts/elements/pfdc-pet-carousel/element';
import { PFDCFullscreenCarouselElement } from '../../../../dotcom/scripts/elements/pfdc-fullscreen-carousel/element';
import { PFDCCollapsableElement } from '../../../../dotcom/scripts/elements/pfdc-collapsable/element';

export default function initCustomElements() {
    customElements.define('pf-accordion', PFAccordion);
    customElements.define('pf-ad', PFAd);
    customElements.define('pf-app', PFApp);
    customElements.define('pf-dynamic', PFDynamic);
    customElements.define('pf-element', PFElement);
    customElements.define('pf-ensighten', PFEnsighten);
    customElements.define('pf-recaptcha', PFRecaptcha);
    customElements.define('pf-truncate', PFTruncate);

    customElements.define('pfdc-tracking-bridge', PFDCTrackingBridge);
    customElements.define('pfdc-adoption-inquiry-form', PFDCAdoptionInquiryForm);
    customElements.define('pfdc-clipboard', PFDCClipboard);
    customElements.define('pfdc-content-touts', PFDCContentTouts);
    customElements.define('pfdc-country-select', PFDCCountrySelect);
    customElements.define('pfdc-dispatcher-select', PFDCDispatcherSelectElement);
    customElements.define('pfdc-favorite-btn', PFDCFavoriteBtnElement);
    customElements.define('pfdc-footer', PFDCFooter);
    customElements.define('pfdc-header', PFDCHeader);
    customElements.define('pfdc-header-dropdown', PFDCHeaderDropdown);
    customElements.define('pfdc-header-dropdown-resources', PFDCHeaderDropdownResources);
    customElements.define('pfdc-hello-bar', PFDCHelloBar);
    customElements.define('pfdc-input', PFDCInput);
    customElements.define('pfdc-lazy-load', PFDCLazyLoad);
    customElements.define('pfdc-login-form', PFDCLoginForm);
    customElements.define('pfdc-mobile-menu', PFDCMobileMenu);
    customElements.define('pfdc-modal', PFDCModal);
    customElements.define('pfdc-optins', PFDCOptins);
    customElements.define('pfdc-password-validator', PFDCPasswordValidator);
    customElements.define('pfdc-position-tracker', PFDCPositionTracker);
    customElements.define('pfdc-postal-code-input', PFDCPostalCodeInput);
    customElements.define('pfdc-recommendations', PFDCRecommendations);
    customElements.define('pfdc-registration-form', PFDCRegistrationForm);
    customElements.define('pfdc-saved-search-list', PFDCSavedSearchList);
    customElements.define('pfdc-sticky', PFDCSticky);
    customElements.define('pfdc-tooltip-controller', PFDCTooltipController);
    customElements.define('pfdc-pet-card', PFDCPetCard);

    customElements.define('pfdc-embedded-carousel-video', PFDCEmbeddedCarouselVideoElement);
    customElements.define('pfdc-pet-carousel', PFDCPetCarouselElement);
    customElements.define('pfdc-fullscreen-carousel', PFDCFullscreenCarouselElement);
    customElements.define('pfdc-collapsable', PFDCCollapsableElement);
}
