import TemplateRegistry from '../../../../core/scripts/lib/wirey/TemplateRegistry';
import savedSearchListTemplate from '../../templates/savedSearchList.html';
import contentToutsTwoUp from '../../templates/contentToutsTwoUp.html';
import recommendationsFullRow from '../../templates/recommendationsFullRowTemplate.html';

export default function initWireyTemplates() {
    TemplateRegistry.register('savedSearchList', savedSearchListTemplate);
    TemplateRegistry.register('contentToutsTwoUp', contentToutsTwoUp);
    TemplateRegistry.register('recommendationsFullRow', recommendationsFullRow);
}
