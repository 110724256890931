import { PFDCFormElement } from '../pfdc-form/element';
import Config from '../../../../core/scripts/lib/Config';
import loadReCaptchaScript from '../../../../core/scripts/elements/pf-recaptcha/loadReCaptchaScript';
import axios from 'axios';

function updateEvent(event, ctx) {
    return new Promise(resolve => {
        ctx.eventEmitter.on(event, resolve);
    });
}

export class PFDCAdoptionInquiryFormElement extends PFDCFormElement {
    get animalId() {
        return this.getAttribute('animal-id');
    }

    onInit() {
        super.onInit();
        this.isSubmitting = false;
        this.isValidated = false;
    }

    async beforeSubmission() {
        if (this.hasAttribute('no-captcha')) {
            super.beforeSubmission();
            return;
        }

        loadReCaptchaScript();

        this.dispatchAction('recaptcha.execute', this.uid);
        this.submitBtn.disabled = true;
        this.submitBtn.classList.add('u-isActive');
        await updateEvent('recaptcha.validation', this);
    }

    async submitToEndpoint(formData) {
        if (!this.isSubmitting) {
            this.isSubmitting = true;
            try {
                const response = await axios.post(
                    `/adoption-inquiry/${this.animalId}/`,
                    formData,
                    {
                        headers: {
                            // Need this content-type here
                            'Content-Type': 'multipart/form-data',
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                        credentials: 'same-origin',
                    }
                );

                // const response = await fetch(
                //     `/adoption-inquiry/${this.animalId}/`,
                //     {
                //         method: 'POST',
                //         headers: {
                //             'Content-Type': 'multipart/form-data',
                //             // Need this content-type here
                //             'X-Requested-With': 'XMLHttpRequest',
                //         },
                //         credentials: 'same-origin',
                //         body: formData,
                //     }
                // );
                // const data = await response.json();
                return response.data;
            } catch (e) {
                // TODO:
                // this.dispatchAction('analytics', { eventId: 'Consumer241' });
                console.error(e);
            } finally {
                this.isSubmitting = false;
            }
        }
    }

    handleSubmissionSuccess(response) {
        const petDetailAnalytics =
            typeof window.petDetailAnalytics !== 'undefined'
                ? window.petDetailAnalytics
                : {};
        if (response.success) {
            window.location = response.redirect;
            if (Config.page['user_auth']) {
                this.dispatchAction('analytics', {
                    eventId: 'Consumer300',
                    petDetailAnalytics,
                });
            } else {
                this.dispatchAction('analytics', {
                    eventId: 'Consumer299',
                    petDetailAnalytics,
                });
            }
            return;
        } else {
            this.handleSubmissionError(response);
        }
    }

    /**
     * Handles an error response
     * @param {Object} response
     */
    handleSubmissionError(response) {
        super.handleSubmissionError(response);

        const loggedInEventId = Config.page['user_auth']
            ? 'Consumer242'
            : 'Consumer241';

        this.dispatchAction('analytics', { eventId: loggedInEventId });
        this.dispatchAction('analytics', {
            eventId: 'Consumer327',
            errorData: response.errors,
        });
    }

    onUpdated(event) {
        super.onUpdated(event);

        if (event.detail.type === 'recaptcha.initialized') {
            this.submitBtn.disabled = false;
        }
        if (event.detail.type === 'recaptcha.reset') {
            this.submitBtn.disabled = false;
            this.submitBtn.classList.remove('u-isActive');
        }
    }
}

export default PFDCAdoptionInquiryFormElement;
