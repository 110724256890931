import React, { Component } from 'react';
import PropTypes from 'prop-types';
import buildClassNames from 'classnames';

class HeartBtn extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        petName: PropTypes.string.isRequired,
        cta: PropTypes.bool,
        extensionClassNames: PropTypes.object,
        favorited: PropTypes.bool,
        sessionFavorited: PropTypes.bool,
        awaiting: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        buttonRef: PropTypes.object,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        cta: false,
        extensionClassNames: {},
        favorited: false,
        sessionFavorited: false,
        awaiting: false,
        buttonRef: React.createRef(),
    };

    handleClick = () => {
        this.props.onClick();
    };

    get classNames() {
        return buildClassNames({
            ['favoriteBtn']: true,
            ['s-favoriteBtn_favorited']: this.props.favorited,
            ['s-favoriteBtn_sessionFavorited']: this.props.sessionFavorited,
            ['s-favoriteBtn_awaiting']: this.props.awaiting,
            ...this.props.extensionClassNames,
        });
    }

    get cta() {
        if (!this.props.cta) {
            return null;
        }

        if (this.props.favorited) {
            return 'Unfavorite';
        } else {
            return 'Favorite';
        }
    }

    get ariaLabel() {
        if (this.props.favorited) {
            return `Remove ${this.props.petName} from your favorites`;
        } else {
            return `Favorite ${this.props.petName}`;
        }
    }

    render() {
        return (
            <button
                className={this.classNames}
                aria-label={this.ariaLabel}
                onClick={this.handleClick}
                data-test="Favorite_Btn"
                ref={this.props.buttonRef}
            >
                <svg focusable="false" role="presentation" aria-hidden="true">
                    <use xlinkHref="#icon-favorite_outline" />
                </svg>
                {this.cta}
            </button>
        );
    }
}

export default HeartBtn;
