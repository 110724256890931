import {
    PFDCEmbeddedVideoElement,
    stop,
    play,
} from '../pfdc-embedded-video/element.js';
import { scheduleMicrotask } from '../../../../core/scripts/util/util';

export class PFDCEmbeddedCarouselVideoElement extends PFDCEmbeddedVideoElement {
    /**
     * Index of video slide in carousel
     * @return {String}
     */
    get index() {
        return parseInt(this.getAttribute('index'), 10);
    }

    /**
     * Boolean to track if modal has been opened
     * @type {Boolean}
     */
    modalHasOpened = false;

    /**
     * Listens for carousel transition events to play and pause video
     * @method onUpdated
     * @param {Object} event
     * @public
     */
    onUpdated(event) {
        super.onUpdated(event);
        if (event.detail.type === 'pet-carousel.slide-settle') {
            if (!this.modalHasOpened) {
                return;
            }

            if (event.detail.imgIndex !== this.index) {
                scheduleMicrotask(() => {
                    this[stop]();
                });
            }
            if (event.detail.imgIndex === this.index) {
                scheduleMicrotask(() => {
                    this[play]();
                });
            }
        }

        if (event.detail.type === 'fullscreen-carousel.open') {
            if (!this.modalHasOpened) {
                this.modalHasOpened = true;
            }
        }
    }
}
