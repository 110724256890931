/* eslint-disable indent */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import withLazyLoad from '../../../../../hoc/withLazyLoad';
import PropTypes from 'prop-types';
import { trackEvent } from '../../../../../../../../core/scripts/analytics';
import { result } from 'lodash';

const ageMap = {
    baby: 'child',
    young: 'adult',
    adult: 'adult',
    senior: 'senior',
    geriatric: 'geriatric', // not used in PF
};

const sizeMap = {
    'extra large': 'giant',
    large: 'large',
    medium: 'medium',
    small: 'small',
    unknown: 'toy', // not used in PF
};

// Notes:
// If you don't have the PFF API vars in you .env file, grab them from .env.dist
// Dog Animal test URL: http://www.petfinder.test/dog/mn718-animal-1-305/mn/minneapolis/mn718-phone-number-only-primary-location-organization-2-mn718/
// Cat Animal test URL: http://www.petfinder.test/cat/tetessfdsa-52/nj/jersey-city/nj333-petfinder-test-account-no-adoptable-pets-updated-alertyou-just-got-xss-attacked-by-this-organization-nj333/
class PetFoodFinder extends Component {
    rootRef = React.createRef();

    state = {
        auth: null,
        products: null,
        isCurrentlyInView: false,
        impressionsSent: false,
        recommendationId: null,
    };

    static propTypes = {
        petName: PropTypes.string.isRequired,
        petId: PropTypes.number.isRequired,
        animalJSON: PropTypes.string.isRequired,
        pffApiUrl: PropTypes.string.isRequired,
        pffApiKey: PropTypes.string.isRequired,
    };

    componentDidMount() {
        if (this.props.pffApiUrl && this.props.pffApiKey) {
            // Full Animal Data
            const animalJSON = JSON.parse(this.props.animalJSON);

            // 1. Get Auth Key
            this.getAuthKey(animalJSON);
        }

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const { isIntersecting } = entry;
                    // we want to dect when it wasnt in view and now is
                    if (!this.state.isCurrentlyInView && isIntersecting) {
                        // if we just came into view, set the state to reflect that, and then check if we should send the impressions event
                        this.setState({ isCurrentlyInView: isIntersecting }, this.checkViewAndLoaded);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );

        observer.observe(this.rootRef.current);
    }

    // * Step 1: Get Auth Key
    async getAuthKey(animalJSON) {
        try {
            const response = await fetch(`${this.props.pffApiUrl}auth/${this.props.pffApiKey}`);
            const auth = await response.json();
            this.setState({ auth });
            // 2. Get Breed List
            this.fetchPetFoodFinderBreedList(
                animalJSON.animal.sex.toLowerCase(),
                ageMap[animalJSON.animal.age.toLowerCase()],
                sizeMap[animalJSON.animal.size.toLocaleLowerCase()],
                animalJSON.animal.primary_breed.name
            );
        } catch (e) {
            console.error('Step 1: ', e);
        }
    }

    // * Step 2: Get List of Breeds from PetFoodFinder
    async fetchPetFoodFinderBreedList(gender, life_stage, size, breedName) {
        try {
            const response = await fetch(`${this.props.pffApiUrl}breeds/dog/${gender}`, {
                headers: {
                    Authorization: `Bearer ${this.state.auth.access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            const PFFBreedList = await response.json();

            // * Step 3: Figuire out if the animal breed is in the supported breeds list
            const breedMatch = PFFBreedList.find(o => o.breed.toLowerCase() === breedName.toLowerCase());

            /*
                If we are unable to find a suitable breed match, we build a string that looks like:
                dog_{M or F}_unknown_unknown_{size}

                This should fetch a generic food recommendation based on the size of the dog.
                */
            const pffBreeds = breedMatch
                ? [breedMatch.concat_fr_return_breed]
                : [`dog_${gender.charAt(0).toUpperCase()}_unknown_unknown_${size}`];

            // Build Food Request Body
            const body = JSON.stringify({
                breeds: pffBreeds,
                details: false,
                gender,
                grain_free: 'undecided',
                lang_code: 'en',
                life_stage,
                max_results: 3,
                species: 'dog',
            });

            // 4. Get Food Recommendations
            void this.getFoodRecommendations(body);
        } catch (e) {
            console.error('Step 2: ', e);
        }
    }

    // * Step 4: Get Food Recommendations
    async getFoodRecommendations(body) {
        try {
            if (this.state.auth) {
                const response = await fetch(`${this.props.pffApiUrl}simple_recommend`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${this.state.auth.access_token}`,
                        'Content-Type': 'application/json',
                    },
                    redirect: 'follow',
                    body,
                });
                const results = await response.json();
                // 5. Get Food Details
                void this.getFoodDetails(results);
            }
        } catch (e) {
            console.error('Step 4: ', e);
        }
    }

    async getFoodDetails(results) {
        try {
            // Recommendation API connection and recommendation IDs
            const recommendedProductIDs = results ? results.products : null;
            const recommendationId = results ? results.recommend_id : null;

            // Store the recommendation ID in state for use in analytics
            this.setState({ recommendationId });

            // loop through the recommendation IDs and get the product details
            // then put those products into state
            const promises = recommendedProductIDs.map(recommendationID =>
                fetch(`${this.props.pffApiUrl}product/${recommendationID}`, {
                    headers: {
                        Authorization: `Bearer ${this.state.auth.access_token}`,
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
            );

            const products = await Promise.all(promises);

            this.setState({ products }, this.checkViewAndLoaded);
        } catch (e) {
            console.error('Step 5: ', e);
        }
    }

    // Check if We should send Analytics Impressions Event
    checkViewAndLoaded() {
        if (this.state.isCurrentlyInView && this.state.products && !this.state.impressionsSent) {
            this.state.products.forEach((product, index) => {
                trackEvent({
                    type: 'EVENT TYPE',
                    category: 'pet food finder',
                    action: 'product impression',
                    label: `${product.id_product} | ${product.name}`,
                    dimensions: { 126: this.state.recommendationId },
                });
            });

            // Set that we've sent the impressions event so it cannot be sent again
            this.setState({ impressionsSent: true });
        }
    }

    render() {
        // Full Animal Data
        const animalJSON = JSON.parse(this.props.animalJSON);

        // props for the title
        const { sex, age, size, primary_breed: breed, species } = animalJSON.animal;
        const breedName = breed.name;
        const speciesName = species.name;
        const animalName = this.props.petName;

        // build the title
        const petFoodFinderTitle = `${animalName} might do well on the following pet food, based on these characteristics: ${[
            breedName,
            age,
            sex,
            size,
        ]
            .filter(Boolean)
            .join(', ')}`;

        // Don't render if we don't have both the API URL and API Key
        if (!this.props.pffApiUrl || !this.props.pffApiKey) {
            return '';
        }

        // If its null, still loading, if its an array, we have products, so if empty, we have no products and don't render
        if (this.state.products && this.state.products.length === 0) {
            return '';
        }

        return (
            <Fragment>
                <div className="tier tier_full tier_flush@maxMd m-tier_coverFixedBottom u-vr8x">
                    <div className="tier-inner tier-inner_noPadSides@maxLg">
                        <div className="pet-food-finder">
                            <div className="pet-food-finder__main">
                                <h2 className="pet-food-finder__main__header">{petFoodFinderTitle}</h2>
                                <div className="pet-food-finder__main__products" ref={this.rootRef}>
                                    {this.state.products &&
                                        this.state.products.map((product, index) => {
                                            return (
                                                <a
                                                    className="pet-food-finder__main__products__product"
                                                    href={product.purina_url}
                                                    target="_blank"
                                                    key={product.id_product}
                                                    onClick={() => {
                                                        trackEvent({
                                                            type: 'EVENT TYPE',
                                                            category: 'pet food finder',
                                                            action: 'view product',
                                                            label: `${product.id_product} | ${product.name}`,
                                                            dimensions: {
                                                                126: this.state.recommendationId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <div className="pet-food-finder__main__products__product__image">
                                                        <img src={product.image} alt={product.name} />
                                                    </div>

                                                    <div className="pet-food-finder__main__products__product__name">
                                                        {product.name}
                                                    </div>

                                                    <div className="pet-food-finder__main__products__product__description">
                                                        {product.description_short ||
                                                            product.description ||
                                                            product.description_long}
                                                    </div>

                                                    <button className="btn pet-food-finder__main__products__product__button">
                                                        VIEW PRODUCT
                                                    </button>
                                                </a>
                                            );
                                        })}
                                    {!this.state.products &&
                                        [1, 2, 3].map(index => (
                                            <div
                                                className="pet-food-finder__main__products__product--loading"
                                                key={index}
                                            >
                                                <div className="loader m-loader_sizeMd u-isActive">
                                                    <svg role="img" focusable="false" viewBox="25 25 50 50">
                                                        <circle
                                                            className="path"
                                                            cx="50"
                                                            cy="50"
                                                            r="20"
                                                            fill="none"
                                                            strokeWidth="5"
                                                            stroke="#000"
                                                            strokeDasharray="39.25, 117.75"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="pet-food-finder__secondary">
                                <div className="pet-food-finder__secondary__icon">
                                    <svg focusable="false" role="presentation" aria-hidden="true">
                                        <use xlinkHref="#icon-petFoodFinder" />
                                    </svg>
                                </div>

                                <div className="pet-food-finder__secondary__content">
                                    <div className="pet-food-finder__secondary__content__header">
                                        <h3>How Pet Food Finder Works</h3>
                                    </div>
                                    <div className="pet-food-finder__secondary__content__description">
                                        This tool takes into account a pet's unique traits and food preferences, then
                                        matches them with an optimal Purina formula. It's designed to help give your pet
                                        their best life possible through the power of nutrition—and make the food
                                        finding process easier for you.
                                    </div>
                                </div>
                                <a
                                    href="https://www.purina.com/pet-food-finder-direct"
                                    target="_blank"
                                    className="btn btn_white pet-food-finder__secondary__button"
                                    onClick={() => {
                                        trackEvent({
                                            type: 'EVENT TYPE',
                                            category: 'pet food finder',
                                            action: 'try food finder hyperlink',
                                        });
                                    }}
                                >
                                    FIND MY PET'S FOOD
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withLazyLoad(PetFoodFinder);
