import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Cookies from 'js-cookie';
import socialLoginStateController from '../SocialLogin/controller/SocialLoginStateController';
import { noop } from '../../../../../core/scripts/util/util';
import { COOKIE_NAME, COOKIE_EXPIRATION } from '../../../constants/favorites';
import FavoritesAPIService from '../../../api-services/FavoritesAPIService';
import favoritesStateController from '../../../state-controllers/favoritesStateController';

import HeartBtn from './components/HeartBtn';
import { SSO_MODAL_ACTIONS, handleSSOModal } from '../../../util/ssoModalHelpers';

class FavoriteBtn extends Component {
    state = {
        favorited: false,
        sessionFavorited: false,
        awaitingResponse: true,
    };

    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        petName: PropTypes.string.isRequired,
        petId: PropTypes.number.isRequired,
        cta: PropTypes.bool,
        extensionClassNames: PropTypes.object,
        guest: PropTypes.bool,
        favoritedCallback: PropTypes.func,
        authSuccessRedirectUrl: PropTypes.string,
        analyticTriggerLabel: PropTypes.string,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        cta: false,
        guest: false,
        extensionClassNames: {},
        favoritedCallback: noop,
        authSuccessRedirectUrl: null,
        analyticTriggerLabel: 'favoriteButtonPetCard',
    };

    /**
     * Button ref for focus management
     * @type {Object}
     */
    buttonRef = React.createRef();

    async componentDidMount() {
        if (!this.props.guest) {
            this.checkIfFavorite();
        }

        favoritesStateController.subscribe(payload => {
            this.setState(prevState => {
                const favorited = this.isFavorite;
                const sessionFavorited = this.isFavorite;

                if (favorited !== prevState.favorited) {
                    return { favorited, sessionFavorited };
                }
            });
        });
    }

    get isFavorite() {
        if (this.props.guest) {
            return false;
        } else {
            return favoritesStateController.isFavorite(this.props.petId);
        }
    }

    /**
     * Check if pet is favorited and update state
     * @method checkIfFavorite
     * @private
     */
    checkIfFavorite() {
        const nextState = {
            favorited: false,
            awaitingResponse: false,
        };
        const isFavorite = this.isFavorite;
        const pendingFavorite = this.getIdFromCookie() === this.props.petId;

        // already favorite, just update state
        if (isFavorite) {
            nextState.favorited = true;
        }

        // and delete cookie if it was pending but already a favorite
        if (isFavorite && pendingFavorite) {
            this.deleteCookie();
        }

        // not already favorite but is pending, add to favorites
        if (!isFavorite && pendingFavorite) {
            this.addFavorite();
            this.deleteCookie();
        }

        this.setState(nextState);
    }

    /**
     * @method addFavorite
     * @private
     */
    async addFavorite() {
        const response = await FavoritesAPIService.setFavorite(this.props.petId);

        if (!response.error) {
            favoritesStateController.addFavorite(this.props.petId);
        }
    }

    /**
     * @method removeFavorite
     * @private
     */
    async removeFavorite() {
        const response = await FavoritesAPIService.removeFavorite(this.props.petId);

        if (!response.error) {
            favoritesStateController.removeFavorite(this.props.petId);
        }
    }

    /**
     * Check local favorite state and determine whether to add or remove pet
     * @method toggleFavorite
     * @private
     */
    async toggleFavorite() {
        if (this.state.awaitingResponse) {
            return;
        }

        this.setState({ awaitingResponse: true });

        if (this.state.favorited) {
            await this.removeFavorite();
        } else {
            this.onFavoritedCallback();
            await this.addFavorite();
        }

        this.setState({ awaitingResponse: false });
    }

    /**
     * Get petId from cookie
     * @method getIdFromCookie
     * @returns {Number} petId
     * @private
     */
    getIdFromCookie() {
        const cookie = Cookies.get(COOKIE_NAME);
        let petId = null;

        if (cookie) {
            petId = parseInt(cookie, 10);
        }

        return petId;
    }

    /**
     * Set cookie for pending favorite
     * @method setCookie
     * @private
     */
    setCookie() {
        Cookies.set(COOKIE_NAME, this.props.petId, {
            expires: COOKIE_EXPIRATION,
            path: '/',
        });
    }

    /**
     * Delete the pending favorite cookie
     * @method deleteCookie
     * @private
     */
    deleteCookie() {
        Cookies.remove(COOKIE_NAME);
    }

    /**
     * Handle callback on favorite
     * @method onFavoritedCallback
     * @private
     */
    async onFavoritedCallback() {
        this.props.favoritedCallback();
    }

    /**
     * Callback function for authentication success
     * @method onAuthSuccessCallback
     * @private
     */
    onAuthSuccessCallback = () => {
        const url = this.props.authSuccessRedirectUrl;

        if (typeof url !== 'string') {
            location.reload();
            return;
        }

        window.location = url;
    };

    onGuestClick() {
        this.onFavoritedCallback();

        this.setCookie();

        if (window.ssoFlagIsActive) {
            handleSSOModal(SSO_MODAL_ACTIONS.open, 'pet favorite');
        } else {
            // OLD MODAL
            socialLoginStateController.openLoginModal({
                display: 'signUp',
                context: 'favorites',
                analyticTriggerLabel: this.props.analyticTriggerLabel,
                refocusTarget: this.buttonRef.current,
                onSuccess: this.onAuthSuccessCallback,
            });
        }
    }

    onUserClick() {
        this.toggleFavorite();
    }

    handleClick = () => {
        if (this.props.guest) {
            this.onGuestClick();
        } else {
            this.onUserClick();
        }
    };

    render() {
        return (
            <HeartBtn
                petName={this.props.petName}
                favorited={this.state.favorited}
                sessionFavorited={this.state.sessionFavorited}
                awaiting={this.state.awaitingResponse}
                cta={this.props.cta}
                extensionClassNames={this.props.extensionClassNames}
                onClick={this.handleClick}
                buttonRef={this.buttonRef}
            />
        );
    }
}

export default FavoriteBtn;
