import { PFElement } from '../../../../core/scripts/elements/pf-element/element';

/**
 * Reference to this elements' tag name
 * @const
 * @type {string}
 */
const ELEMENT_TAG_NAME = 'pfdc-position-tracker';

/**
 * Stores reference string selectors for various queryable items in this element
 * @const
 * @type {Object}
 */
const ELEMENT_SELECTORS = {
    SECTION: `${ELEMENT_TAG_NAME}-section`,
    ANCHOR: `${ELEMENT_TAG_NAME}-anchor`,
    NAV: `${ELEMENT_TAG_NAME}-nav`,
};

/**
 * State classes for CSS
 * @const
 * @type {string}
 */
const CLASSES = {
    ACTIVE: 'isActive',
};

/**
 * Distance threshold from top of screen
 * @const
 * @type {Number}
 */
const THRESHOLD = 200;

/**
 * @class PFDCPositionTrackerElement
 * @extends PFElement
 */
export class PFDCPositionTrackerElement extends PFElement {
    /**
     * An instance of the element is created or upgraded. Useful for initializing state,
     * settings up event listeners, or creating shadow dom.
     */
    constructor() {
        super();

        this.options = {
            rootMargin: `-${THRESHOLD}px 0px -${THRESHOLD}px 0px`,
            threshold: [0, 1],
        };
    }

    /**
     * Called every time the element is inserted into the DOM. Useful for running setup code,
     * such as fetching resources or rendering. Generally, you should try to delay work until
     * this time.
     */
    async connectedCallback() {
        await super.connectedCallback();

        this.sections = Array.from(
            document.querySelectorAll(`[${ELEMENT_SELECTORS.SECTION}]`)
        );
        this.nav = this.querySelector(`[${ELEMENT_SELECTORS.NAV}]`);
        this.anchors = Array.from(
            this.querySelectorAll(`[${ELEMENT_SELECTORS.ANCHOR}]`)
        );

        this.observer = new IntersectionObserver(
            this.onIntersected.bind(this),
            this.options
        );

        // observe all observable page sections
        for (let i = 0; i < this.sections.length; i++) {
            this.observer.observe(this.sections[i]);
        }

        this.addEventListener('click', this.onClicked);
    }

    /**
     * Finds active section based on proximity to window
     *
     * @method activeSectionId
     * @return {String} sectionId
     */
    get activeSectionId() {
        let activeIndex = 0;

        // find index of section closest to threshold without going over
        for (let i = 0; i < this.sections.length; i++) {
            if (this.sections[i].getBoundingClientRect().top < THRESHOLD) {
                activeIndex = i;
            }
        }

        return this.sections[activeIndex].dataset.sectionId;
    }

    /**
     * Updates classes on navigation based on matching section id
     *
     * @method updateNav
     * @param {string} id
     */
    updateNav(id) {
        for (let i = 0; i < this.anchors.length; i++) {
            this.anchors[i].classList.remove(CLASSES.ACTIVE);

            if (id === this.anchors[i].dataset.anchorId) {
                this.anchors[i].classList.add(CLASSES.ACTIVE);
                this.scrollToActiveAnchor(this.anchors[i]);
            }
        }
    }

    /**
     * Scroll active anchor into view if not totally visible already
     *
     * @method updateNav
     * @param {HTMLElement} element
     */
    scrollToActiveAnchor(element) {
        const width = element.getBoundingClientRect().width;
        const leftOffset = element.getBoundingClientRect().left;

        if (
            leftOffset + width > this.nav.getBoundingClientRect().width ||
            leftOffset < 0
        ) {
            this.nav.scrollLeft = element.offsetLeft;
        }
    }

    /**
     * Handles observed intersections
     *
     * @method onIntersected
     */
    onIntersected() {
        this.updateNav(this.activeSectionId);
    }

    /**
     * Handler for standard click events
     *
     * @method onClicked
     * @param {Event} ev
     */
    onClicked(ev) {
        switch (true) {
            case this.anchors.includes(ev.target):
                this.updateNav(ev.target.dataset.anchorId);
                break;
            default:
                break;
        }
    }
}

export default PFDCPositionTrackerElement;
