import initHypernovaRenderReact from '../utils/hypernova-render-react';

import PdpFavoriteBtns from '../../react-components/specifics/PdpFavoriteBtns';
import FavoriteBtn from '../../react-components/specifics/FavoriteBtn';
import SocialSignInButton from '../../react-components/specifics/SocialLogin/index';
import HappyTails from '../../react-components/pages/animal-detail/AnimalDetailPage/components/HappyTails';
import PetFoodFinder from '../../react-components/pages/animal-detail/AnimalDetailPage/components/PetFoodFinder';

export default function initReactServerComponents() {
    const components = {
        PdpFavoriteBtns,
        FavoriteBtn,
        SocialSignInButton,
        HappyTails,
        PetFoodFinder,
    };

    initHypernovaRenderReact(components);
}
