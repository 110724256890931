import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import AnimalModel from '../../../models/AnimalModel';
import { analyticsFavorites004, analyticsFavorites005 } from '../../../analytics/favorites';

import FavoriteBtn from '../FavoriteBtn';

/**
 * Container id of the element to portal this modal component into
 * @type {string}
 */
const ACTION_CARD_PORTAL_SELECTOR = '[data-PdpFavoriteBtns-actionCardPortal]';
const FOOTER_PORTAL_DESKTOP_SELECTOR = '#PdpFavoriteBtns-desktopPortal';
const FOOTER_PORTAL_MOBILE_SELECTOR = '#PdpFavoriteBtns-mobilePortal';

class PdpFavoriteBtns extends Component {
    /**
     * @type {Object}
     * @static
     */
    static propTypes = {
        petName: PropTypes.string.isRequired,
        petId: PropTypes.number.isRequired,
        animalJSON: PropTypes.string.isRequired,
        guest: PropTypes.bool,
    };

    /**
     * @type {Object}
     * @static
     */
    static defaultProps = {
        guest: false,
    };

    get animalModel() {
        return AnimalModel.fromSearchResults(JSON.parse(this.props.animalJSON));
    }

    handleCardFavorited = () => {
        analyticsFavorites004(this.animalModel, this.props.guest);
    };

    handleFooterFavorited = () => {
        analyticsFavorites005(this.animalModel, this.props.guest);
    };

    render() {
        const actionCardBtnClasses = {
            ['favoriteBtn_pdp']: true,
        };

        const footerBtnDesktopClasses = {
            ['favoriteBtn_primary']: true,
        };

        const footerBtnMobileClasses = {
            ['favoriteBtn_primary']: true,
            ['u-displayBlock']: true,
        };

        const props = {
            petName: this.props.petName,
            petId: this.props.petId,
            guest: this.props.guest,
            analyticTriggerLabel: 'favoriteButtonPetDetail',
        };

        const actionCardBtn = (
            <FavoriteBtn
                {...props}
                favoritedCallback={this.handleCardFavorited}
                extensionClassNames={actionCardBtnClasses}
                cta={true}
            />
        );

        const footerBtnDesktop = (
            <FavoriteBtn
                {...props}
                favoritedCallback={this.handleFooterFavorited}
                extensionClassNames={footerBtnDesktopClasses}
            />
        );

        const footerBtnMobile = (
            <FavoriteBtn
                {...props}
                favoritedCallback={this.handleFooterFavorited}
                extensionClassNames={footerBtnMobileClasses}
            />
        );

        const actionCardPortals = [];

        document.querySelectorAll(ACTION_CARD_PORTAL_SELECTOR).forEach(element => {
            actionCardPortals.push(ReactDOM.createPortal(actionCardBtn, element));
        });

        return (
            <Fragment>
                {actionCardPortals}
                {ReactDOM.createPortal(footerBtnDesktop, document.querySelector(FOOTER_PORTAL_DESKTOP_SELECTOR))}
                {ReactDOM.createPortal(footerBtnMobile, document.querySelector(FOOTER_PORTAL_MOBILE_SELECTOR))}
            </Fragment>
        );
    }
}

export default PdpFavoriteBtns;
